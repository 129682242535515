<template>
  <div style="text-align:center;margin-top: 200px;">

    <div>
      <img width="90" src="@/assets/logo.png" style="border-radius: 50%"  alt="">
      <h3>红了樱桃</h3>
    </div>

    <div>
      <p style="color:#999;margin-top:25px;">出圈，是一切的开始</p>
    </div>
  </div>
</template>
